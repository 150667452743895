<template>
  <b-form-checkbox
    v-model="isSelected"
    :disabled="isSearchMonthlyCheapestFare || isDisabled"
    switch
    :class="`custom-control-warning CustomCheckbox ${(isSearchMonthlyCheapestFare || isDisabled) ? 'label_disabled' : ''}`"
    @input="val => handleUpdate(val)"
  >
    <span class="text-nowrap text-body font-weight-bolder">{{ $t('flight.classBooking') }}</span>
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import {
  ref, watch,
} from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
    isSearchMonthlyCheapestFare: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false)

    watch(() => props.isSearchClassBooking, val => {
      if (val) {
        isSelected.value = val
      }
    }, { immediate: true })

    watch(() => props.isSearchMonthlyCheapestFare, val => {
      if (val) {
        isSelected.value = false
      }
    }, { immediate: true })

    function handleUpdate(val) {
      emit('update:isSearchClassBooking', val)
    }
    return {
      isSelected,
      handleUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.CustomCheckbox::v-deep {
  display: flex;
  align-items: center;
  .custom-control-label {
    margin: 4px 0 !important;

    &.label_disabled {
      span {
          color: #999999 !important;
      }
    }

    span {
      font-size: 1.2rem;
    }
  }
}
</style>
